import { IRoseAGGridColumn, fromNowFormatter } from '@rose/common-ui';
import { ITabletExtended, TabletTaskType } from '@rose/types';

export function settingsTabletsColumnsDefs(): IRoseAGGridColumn<ITabletExtended>[] {
  return [
    {
      headerName: 'Tablet-Name',
      cellRenderer: 'TabletNameCell',
      sortable: false,
      minWidth: 150,
    },
    {
      headerName: 'Aufgabe',
      valueFormatter: params => {
        if (params.data?.statusMeta) {
          if (params.data.statusMeta.task.type === TabletTaskType.EWE_TASK) {
            return 'EWE unterschreiben';
          }
          if (params.data.statusMeta.task.type === TabletTaskType.DOCUMENT_TASK) {
            return 'Dokument unterschreiben';
          }
          if (params.data.statusMeta.task.type === TabletTaskType.ANAMNESE_TASK) {
            return `Formular ${params.data?.statusMeta.task.infos?.questionnaire?.title} ausfüllen`;
          }
          if (params.data.statusMeta.task.type === TabletTaskType.QR_CODE_TASK) {
            return params.data.statusMeta.task.type;
          }
        } else {
          if (params.data?.online) {
            return 'Bereit';
          }
          if (!params.data?.online) {
            return 'Schläft';
          }
        }
        return `${params.data?.statusMeta?.task.type}`;
      },
      sortable: false,
      minWidth: 70,
    },
    {
      headerName: 'Aufgabendetails',
      valueFormatter: params => {
        if (params.data?.statusMeta && params.data.statusMeta.task.type) {
          return `Patient: ${params.data.statusMeta.task.patient.vorname} ${params.data.statusMeta.task.patient.name} (${params.data.statusMeta.task.patient.name})`;
        }
        return '';
      },
      sortable: false,
      minWidth: 70,
    },
    {
      headerName: 'Zuletzt online',
      valueGetter: params => params.data?.lastonline,
      valueFormatter: fromNowFormatter,
      sortable: false,
      minWidth: 30,
    },
    {
      headerName: 'Letzte Verwendung',
      valueGetter: params => params.data?.lastactivity,
      valueFormatter: fromNowFormatter,
      sortable: false,
      minWidth: 100,
    },
    {
      headerName: 'verwendet von',
      valueGetter: params => params.data?.lastuser,
      sortable: false,
      minWidth: 100,
    },
    {
      cellRenderer: 'TabletActionButtonsCell',
      cellStyle: { display: 'flex', justifyContent: 'center' },
      sortable: false,
      width: 150,
    },
  ];
}
